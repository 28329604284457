<template>
    <div class="chileTable">
        <OrderProdctTable :groupName="groupName" :params="params" :isRoot="isRoot" />
    </div>
</template>
  
<script>
//import OrderProdctTable from './OrderProdctTable.vue'; // Replace with the correct path to your MyTable component

export default {
    name: "OrderProdctExpand",
    props: {
        groupName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        isRoot:{
            type:Boolean,
            default:false,
        }
    },
    computed: {
    },
    components: {
        OrderProdctTable:()=>import ('./OrderProdctTable.vue')
    },
};
</script>

<style lang="less" scoped>
.chileTable > thead {
    display: none;
}
<template>
    <div class="chileTable">
        <DealerStat :groupName="groupName" :params="params" :isRoot="isRoot" />
    </div>
</template>
  
<script>

export default {
    name: "DealerStatExpand",
    props: {
        groupName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        isRoot:{
            type:Boolean,
            default:false,
        }
    },
    computed: {
    },
    components: {
        DealerStat:()=>import ('./DealerStat.vue')
    },
};
</script>

<style lang="less" scoped>
.chileTable > thead {
    display: none;
}
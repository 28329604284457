<template>
    <div class="container">
        <VeTable :columns="getcolumns" :tableData="tableData" :expand-option="expandOption" row-key-field-name="groupName"
            max-height="calc(100vh - 192px)" :eventCustomOption="eventCustomOption" />
        <HelpUserExpand v-if="false"></HelpUserExpand>
    </div>
</template>
<script>
import Vue from "vue";
import HelpUserExpand from './HelpUserExpand.vue';
import { GetHelpUserStatList } from "@/api/dealer";

import "vue-easytable/libs/theme-default/index.css";

import { VeTable } from "vue-easytable";

Vue.use(VeTable);

export default {
    name: "HelpUserStat",
    props: {

        groupName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        isRoot: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {

            tableData: [],
            expandOption: {
                expandRowKeys: [], // Store the keys of expanded rows
                render: this.renderExpandContent,
            },
            loading: false,
            eventCustomOption: {
                // body 行事件自定义
                bodyRowEvents: ({ row }) => {
                    return {
                        click: () => {
                            this.onTableRowClick(row);
                        },
                    };
                },
            }
        };
    },
    watch: {
        params: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            var reuslt = await GetHelpUserStatList(this.groupName, {
                current: 1,
                pageSize: 10,
                sorterType: 0,
                filterData: { ...this.params }
            });
            this.tableData = reuslt.data.data;
            this.loading = false;
        },
        //点击行
        onTableRowClick(row) {
            console.log("click",row);
            // if (this.page.filterData.salesCompany != row.item) {
            //     this.setsalesCompany(row.item);
            //     this.onSearch();
            // }
            //组织查询参数

            this.$emit('updatefilterData', this.inputValue);

        },
        //展开子项
        renderExpandContent({ row }) {
            // Customize this function to render the expanded content for each row.
            if(row.typeID==1)
            {
            return (
                <div>
                    <HelpUserExpand groupName={row.groupName} params={this.params} />
                </div>
            );
        }
        else if(row.typeID==2)
            {
            return (
                <div>
                    <van-divider content-position="left">按品鉴师</van-divider>
                    <HelpUserExpand groupName={row.groupName+'HelpSale'} params={this.params} />

                    <van-divider content-position="left">按销售人员</van-divider>
                    <HelpUserExpand groupName={row.groupName+'MainSale'} params={this.params} />
                </div>
            );
        }

        },
        handleRowExpand({ row, expanded }) {
            // Update the list of expanded row keys when a row is expanded or collapsed.
            if (expanded) {
                this.expandOption.expandRowKeys.push(row.__originalIndex__);
            } else {
                const index = this.expandOption.expandRowKeys.indexOf(row.__originalIndex__);
                if (index !== -1) {
                    this.expandOption.expandRowKeys.splice(index, 1);
                }
            }
        },
    },
    mounted() {
        this.fetchData(); // Fetch data when the component is mounted.
    },
    computed: {
        getcolumns() {
            return [
                this.tableData.length > 0 && this.tableData[0].hasChild
                    ? {
                        field: "",
                        key: "icon",
                        // 设置需要显示展开图标的列
                        type: "expand",
                        title: "",
                        width: 40,
                        align: "center",
                    }
                    : {},
                { field: 'groupName', key: 'groupName', width: 80, title:  '部门人员' },
                { field: 'campaignCount', key: 'campaignCount', title:  '活动数' },
                { field: 'campaignTotalCost', key: 'campaignTotalCost', title:  '活动费用' },
                { field: 'campaignComeCount', key: 'campaignComeCount', title:  '到场人数' },
                { field: 'orderAmount', key: 'orderAmount', title:  '动销金额' },
                { field: 'orderCount', key: 'orderCount', title:  '动销数' },
                { field: 'productCount', key: 'productCount', title:  '动销产品数' },
                
                { field: 'dealerCount', key: 'dealerCount', title:  '经销商数' },
            ];
        },
    },
    components: {
        HelpUserExpand,
    }
};
</script>

<style lang="less" scoped>
.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}


/*隐藏子表的表头 */
.chileTable {
    /deep/ thead {
        display: none;
    }
}
</style>
  
<template>
    <div class="container">
        <VeTable :columns="getcolumns" :tableData="tableData" :expand-option="expandOption" row-key-field-name="groupName"
            max-height="calc(100vh - 192px)" :eventCustomOption="eventCustomOption" />
        <OrderProdctExpand v-if="false"></OrderProdctExpand>
    </div>
</template>
<script>
import Vue from "vue";
import OrderProdctExpand from './OrderProdctExpand.vue';
import { GetOrderProductStatList } from "@/api/dealer";

import "vue-easytable/libs/theme-default/index.css";

import { VeTable } from "vue-easytable";

Vue.use(VeTable);

export default {
    name: "OrderProdctTable",
    props: {

        groupName: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
        isRoot: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {

            tableData: [],
            expandOption: {
                expandRowKeys: [], // Store the keys of expanded rows
                render: this.renderExpandContent,
            },
            loading: false,
            eventCustomOption: {
                // body 行事件自定义
                bodyRowEvents: ({ row }) => {
                    return {
                        click: () => {
                            this.onTableRowClick(row);
                        },
                    };
                },
            }
        };
    },
    watch: {
        params: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;
            var reuslt = await GetOrderProductStatList(this.groupName, {
                current: 1,
                pageSize: 10,
                sorterType: 0,
                filterData: { ...this.params }
            });
            this.tableData = reuslt.data.data;
            this.loading = false;
        },
        //点击行
        onTableRowClick(row) {
            console.log("click",row);
            // if (this.page.filterData.salesCompany != row.item) {
            //     this.setsalesCompany(row.item);
            //     this.onSearch();
            // }
            //组织查询参数

            

        },
        //展开子项
        renderExpandContent({ row }) {
            // Customize this function to render the expanded content for each row.
            console.log(row);
            return (
                <div>
                    <OrderProdctExpand groupName={row.groupName} params={this.params} />
                </div>
            );

        },
        handleRowExpand({ row, expanded }) {
            // Update the list of expanded row keys when a row is expanded or collapsed.
            if (expanded) {
                this.expandOption.expandRowKeys.push(row.__originalIndex__);
            } else {
                const index = this.expandOption.expandRowKeys.indexOf(row.__originalIndex__);
                if (index !== -1) {
                    this.expandOption.expandRowKeys.splice(index, 1);
                }
            }
        },
    },
    mounted() {
        this.fetchData(); // Fetch data when the component is mounted.
    },
    computed: {
        getcolumns() {
            return [
                this.tableData.length > 0 && this.tableData[0].hasChild
                    ? {
                        field: "",
                        key: "icon",
                        // 设置需要显示展开图标的列
                        type: "expand",
                        title: "",
                        width: 40,
                        align: "center",
                    }
                    : {},

                { field: 'groupName', key: 'groupName', width: 200, title:  '产品动销' },

                { field: 'totalAmount', key: 'totalAmount', title:  '总动销' },
                { field: 'totalAmount1', key: 'totalAmount1', title:  '当日动销' },
                { field: 'totalAmount2', key: 'totalAmount2', title:  '7日动销' },
                { field: 'totalCount', key: 'totalCount', title:  '总销量' },
                { field: 'totalCount', key: 'totalCount1', title:  '当日销量' },
                { field: 'totalCount', key: 'totalCount2', title:  '7日销量' },

                
                
            ];
        },
    },
    components: {
        OrderProdctExpand,
    }
};
</script>

<style lang="less" scoped>
.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}


/*隐藏子表的表头 */
.chileTable {
    /deep/ thead {
        display: none;
    }
}
</style>
  